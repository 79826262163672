<script setup lang="ts">

import MarinadeLandingReward from "~/components/MarinadeLanding/MarinadeLandingReward.vue";
import TweetIcon from "~/components/Icons/TweetIcon.vue";
import SigninButton from "~/components/SigninButton.vue";
import MarinadeLandingAvailableReward from "~/components/MarinadeLanding/MarinadeLandingAvailableReward.vue";
import MasonryWall from '@yeger/vue-masonry-wall'
import {truncateString} from "~/utils/stringHelpers";

const overview = ref({});
const campaign = ref({});
const posts = ref([]);
const loading = ref(true);
const howDoesItWorkModalVisible = ref(false);
const route = useRoute();
const twitterUserName = route.query?.publicToken ?? null;

const recipient = computed(() => {
    return overview.value?.recipient;
});

const computedPosts = computed(() => {
    return (posts.value)//.slice(0, 3);
});

function fetchData() {
    loading.value = true;
    apiService.getInvitationDetails(twitterUserName).then((response) => {
        overview.value = response.data.overview;
        campaign.value = response.data.campaign;
        posts.value = response.data.posts;
    }).catch(e => {
        errorMessage(e);
        useRouter().push("/");
    }).finally(() => {
        loading.value = false;
    });
}

fetchData();

</script>

<template>
    <Modal v-if="howDoesItWorkModalVisible" @close="howDoesItWorkModalVisible = false">
        <ContentsHowDoesItWorksSlider/>
    </Modal>
    <div class="container-800 mt-8 lg:mt-14">
        <div class="text-4xl lg:text-6xl font-semibold">
            Your invitation to <br/> Marinade Ambassador<br/> Program is here.
        </div>
    </div>
    <PageLoader v-if="loading" class="mt-14"/>
    <div v-else>
        <div class="container-800 mt-8 lg:mt-14 flex items-center flex-wrap gap-3 ">
            <div class="bg-white rounded-2xl p-6 pt-5 font-semibold flex-auto">
                <MarinadeLandingAvailableReward :campaign="overview"/>
            </div>
            <div class="bg-white rounded-2xl p-6 pt-5 font-semibold flex-auto">
                <div class="">Create an ambassador profile at Evangelist</div>
                <div class="flex flex-wrap gap-4 items-center justify-between mt-8">
                    <div class="flex items-center gap-2">
                        <img :src="recipient?.profilePicUrl" alt="" class="rounded-full w-10 h-10">
                        <p class="text-2xl">@{{ recipient?.twitterHandle }}</p>
                    </div>
                    <SigninButton class="btn bg-purple-light hover:bg-black hover:text-white hover:fill-white mt-5 lg:mt-0"/>
                </div>
            </div>
        </div>

        <div class="container-800 mt-10 lg:mt-14 text-2xl font-semibold ">
            Your {{ computedPosts.length }} {{ computedPosts.length == 1 ? "post" : "posts" }} about Marinade {{ computedPosts.length == 1 ? "qualifies" : "qualify" }} for a retroactive reward.
            <button class="text-green-dark" @click="howDoesItWorkModalVisible = true">How does it work?</button>
        </div>

        <div v-if="computedPosts.length" class="container-800 mt-14 items-center gap-3 ">
            <MasonryWall :items="computedPosts" :ssr-columns="1" :column-width="250" :gap="16">
                <template #default="{ item, index }">
                    <div class="p-6 bg-gradient-to-b from-transparent to-grey/20  font-semibold border border-grey rounded-2xl">
                        <div v-html="truncateString(item.content, 180)"></div>
                        <div class="flex items-center justify-between mt-3">
                            <div class="text-black/30">
                                <TimeAgo :date="item.datePosted"/>
                                • {{ item.numImpressions }} impression{{ item.numImpressions > 1 ? "s" : "" }}
                            </div>
                            <TweetIcon/>
                        </div>
                    </div>
                </template>
            </MasonryWall>
        </div>

<!--        <TweetsCarousel :tweets="computedPosts"/>-->

        <div class="container-800  mt-14 ">
            <div class="flex items-center flex-wrap justify-between gap-6 rounded-2xl font-semibold">
                <div>
                    <div class="text-xl lg:text-2xl">This ambassador campaign<br>from Marinade ends in
                        <ExpiresIn :date="campaign.dateEnd"/>
                        .
                    </div>
                    <SigninButton class="mt-6 mb-4 lg:mb-0 lg:mt-4 btn bg-green-dark text-white fill-white hover:bg-black"/>
                </div>
                <div class="pr-12">
                    <MarinadeLandingReward :campaign="campaign" type="extended"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>