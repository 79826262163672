<script setup>
const learnMoreModalVisible = ref(false)
const route = useRoute()
const loading = ref(false)

const showInvitationSection = computed(() => {
    return route.query?.publicToken
})

onMounted(() => {

    trackEvent("landing_page_viewed", {
        brand: "marinade",
        invited: showInvitationSection.value ? true : false,
    });

    trackEvent("marinade_landing_page_viewed");

    if (route.query?.access_token) {
        loading.value = true;
    }

    clearTokens();
    setTimeout(() => {
        initGateway();
    }, 500);
});

definePageMeta({
    layout: "blank",
});

useHead({
    bodyAttrs: {
        class: "body--marinade"
    }
})
</script>

<template>

    <Modal v-if="learnMoreModalVisible" class="modal--content" @close="learnMoreModalVisible = false">
        <ContentsHowDoesItWorksSlider/>
        <div class="text-center mt-8">
            <button class="btn bg-purple-light hover:bg-black hover:text-white" @click="learnMoreModalVisible=false">Done</button>
        </div>
    </Modal>

    <BrandsMarinadeHeader/>

    <PageLoader v-if="loading"/>
    <div v-else>
        <MarinadeLandingInvitationContent v-if="showInvitationSection"/>
        <MarinadeLandingCommonContent v-else/>
        <div class="container-800 mt-10 lg:mt-14">
            <div class="bg-white p-6 pt-5 rounded-2xl font-semibold">
                <div class="">Introducing Evangelist</div>
                <h2 class="text-2xl mt-12">Become a thought leader in your niche.</h2>
                <p class=" mt-3">And get paid while doing that.</p>
                <div class="flex flex-wrap gap-4 items-center justify-between mt-10 lg:mt-16">
                    <div class="">Evangelist helps connect product evangelists with builders while<br>leveraging Solana blockchain for maximum rewarding transparency.
                    </div>
                    <button class="mt-6 lg:mt-0 btn bg-purple-light hover:bg-black hover:text-white text-sm" @click="learnMoreModalVisible  =true">Learn more</button>
                </div>
            </div>
        </div>
        <footer class="mt-8 mb-14">
            <div class="container-800 flex justify-between items-center gap-8">
                <div class="font-semibold">2024 &copy; Evangelist + &copy; Marinade</div>
                <EvangelistAmbasador/>
            </div>
        </footer>
    </div>
</template>